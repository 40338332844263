import {
  IRoseAGGridColumn,
  dateFormatter,
  euroAndCentFormatter,
  booleanJaNeinFormatter,
  percentFormatter,
} from '@rose/common-ui';
import { IRohLeistung, BemaGozType, GozArtType } from '@rose/types';
import { behandlerStore } from '@/state/behandlerStore';

export function debugPatientLeistungenColumnsDefs(): IRoseAGGridColumn<IRohLeistung>[] {
  return [
    {
      headerName: 'extid',
      field: 'extid',
      sortable: true,
      sort: 'asc',
      width: 80,
    },
    {
      headerName: 'Tag',
      field: 'tag',
      valueFormatter: dateFormatter,
      sortable: true,
      width: 70,
    },
    {
      headerName: 'Nummer',
      field: 'nummer',
      sortable: true,
      width: 80,
    },
    {
      headerName: 'Abrechnungsnummer',
      field: 'abrechnungsnummer',
      sortable: true,
      width: 80,
    },
    {
      headerName: 'Bezeichnung',
      field: 'bezeichnung',
      width: 350,
    },
    {
      headerName: 'Anzahl',
      field: 'anzahl',
      sortable: true,
      width: 40,
    },
    {
      headerName: 'Sitzung',
      field: 'sitzung',
      sortable: true,
      width: 40,
    },
    {
      headerName: 'HKP',
      field: 'hkpid',
      sortable: true,
      width: 60,
    },
    {
      headerName: 'Art',
      field: 'art',
      sortable: true,
      valueGetter: params => params.data && BemaGozType[params.data.art],
      width: 70,
    },
    {
      headerName: 'GOZ-Art',
      field: 'gozart',
      sortable: true,
      valueGetter: params => params.data && GozArtType[params.data.gozart],
      width: 80,
    },
    {
      headerName: 'PZ',
      headerTooltip: 'Punktzahl',
      field: 'punktzahl',
      sortable: true,
      width: 50,
    },
    {
      headerName: 'PW',
      headerTooltip: 'Punktwert',
      field: 'punktwert',
      sortable: true,
      width: 60,
    },
    {
      headerName: 'Faktor',
      field: 'faktor',
      sortable: true,
      width: 40,
    },
    {
      headerName: 'Betrag',
      field: 'betrag',
      valueFormatter: euroAndCentFormatter,
      sortable: true,
      width: 80,
    },
    {
      headerName: 'MwSt-Betrag',
      field: 'mwstbetrag',
      valueFormatter: euroAndCentFormatter,
      sortable: true,
      width: 80,
    },
    {
      headerName: 'MwSt-Satz',
      field: 'mwstsatz',
      valueFormatter: percentFormatter,
      sortable: true,
      width: 80,
    },
    {
      headerName: 'Behandler',
      field: 'behandler',
      valueGetter: params =>
        params.data && (behandlerStore.getters.map[params.data.behandler]?.displayText ?? params.data.behandler),
      sortable: true,
      width: 100,
    },
    {
      headerName: 'Abgerechnet',
      field: 'abgerechnet',
      valueFormatter: booleanJaNeinFormatter,
      sortable: true,
      width: 40,
    },
    {
      headerName: 'Abrechnungsdatum',
      field: 'abgerechnetam',
      valueFormatter: dateFormatter,
      sortable: true,
      width: 70,
    },
    {
      headerName: 'Rechnung',
      field: 'rechnung',
      sortable: true,
      width: 70,
    },
    {
      headerName: 'erstelltam',
      field: 'erstelltam',
      valueFormatter: dateFormatter,
      sortable: true,
      width: 80,
    },
    {
      headerName: 'geaendertam',
      field: 'geaendertam',
      valueFormatter: dateFormatter,
      sortable: true,
      width: 80,
    },
  ];
}
