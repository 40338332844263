import { defineComponent } from 'vue';
import { patientenDebugStore } from '@/state/patientenDebugStore';
import { german } from '@rose/common-ui';
import { debugPatientLeistungenColumnsDefs } from '@/components/columns/patienten-debug/leistungen';
export default defineComponent({
  data: function data() {
    return {
      columnDefs: debugPatientLeistungenColumnsDefs(),
      localeText: german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {}
    };
  },
  computed: {
    patient: function patient() {
      return patientenDebugStore.state.selectedPatient;
    },
    leistungen: function leistungen() {
      return patientenDebugStore.state.leistungen;
    }
  },
  beforeMount: function beforeMount() {
    this.gridOptions = {
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        },
        resizable: true
      },
      rowSelection: 'single',
      suppressCellFocus: true
    };
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    }
  }
});