import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import { IRohLeistung } from '../../../types/ts';
import { metricsApi } from '@/services/data';
import { authStore } from '@/state/authStore';
import { sortBy } from 'lodash';

export interface PatientenDebugState {
  selectedPatient: string;
  isLoading: boolean;
  patids: string[];
  leistungen: IRohLeistung[];
}

const patientenDebugState: PatientenDebugState = {
  selectedPatient: '',
  isLoading: false,
  patids: [],
  leistungen: [],
};

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  strict: process.env.NODE_ENV !== 'production',
  state: () => patientenDebugState,
  getters: {},
  mutations: {
    setSelectedPatient(state, selectedPatient: string) {
      state.selectedPatient = selectedPatient;
    },
    setIsLoading(state, isLoading: boolean) {
      state.isLoading = isLoading;
    },
    setPatids(state, patids: string[]) {
      state.patids = patids;
    },
    setLeistungen(state, leistungen: IRohLeistung[]) {
      state.leistungen = leistungen;
    },
  },
  actions: {
    async loadLeistungen(context) {
      const { commit, state } = rootActionContext(context);
      commit.setIsLoading(true);
      try {
        console.log('loadLeistungen', state.selectedPatient);
        const res = await metricsApi.patientenDebug.getPatientLeistungen({ patid: state.selectedPatient });
        commit.setLeistungen(res);
      } catch (e) {
        void authStore.dispatch.notifyError('Fehler beim Laden der Leistungen');
      } finally {
        commit.setIsLoading(false);
      }
    },
    async loadPatids(context) {
      const { commit } = rootActionContext(context);
      commit.setIsLoading(true);
      try {
        let res = await metricsApi.patientenDebug.getPatids();
        res = sortBy(res, [item => parseFloat(item)]);
        commit.setPatids(res);
      } catch (e) {
        void authStore.dispatch.notifyError('Fehler beim Laden der Patids');
      } finally {
        commit.setIsLoading(false);
      }
    },
  },
});

export const patientenDebugStore = store;
